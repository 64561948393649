import React, { useEffect, useState } from "react";
import logo2 from "../../../../img/logo.png";
import { nextNav } from "../../../../store/actions/navActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UserContext } from "../../../../context/UserProvider";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ApiClient, { Payment } from "../../../../services/apiclient";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import AlertMessage from "../../../alert.component";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import logo_balnco from "../../../../img/logo_blanco.png";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const style = {
  width: '100%',
  maxWidth: 'auto',
  bgcolor: 'background.paper',
};

const Inicio = ({ nextNav, amount, status, stp, setStp, mCreditType }) => {
  const { userMain, updateUser } = React.useContext(UserContext);
  const apiClient = ApiClient.getInstance();
  const [paymentMethod, setPaymetMethod] = useState("transfer");
  const [idBanco, setIdBanco] = useState("");
  const [clave, setClave] = useState("");
  const [tarjeta, setTarjeta] = useState("");
  const [contrato, setcontrato] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const history = useHistory();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  function redirect() {
    nextNav(1);
  }
  const handleChange = (event) => {
    setPaymetMethod(event.target.value);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  const handleBanckInfo = async() => {
    setIsLoading(true)
    console.log("handleBanckInfo");
    console.log("contrato");
    console.log(contrato);
    if(contrato ===  ""){
      await apiClient.getActiveCredit().then((data) => {
        console.log("getActiveCredit", data);
        const contrato = data.creditType
        setcontrato(data.creditType);
        if ( contrato === "diremovil" || contrato === "diremovil-promotion" || mCreditType === "diremovil-promotion" ){
          return history.push("/dashboard");
        } else if (contrato === "pabs") {
          if (paymentMethod === "check") {
            let backInfo = {
              isCheque: true,
            };
            apiClient
              .pabsBankInfo(backInfo)
              .then((data) => {
                checkActiveCredit();
                setMessage("Informacion guardada correctamente..");
                setSeverity("success");
                setOpenMessage(true);
                console.log(data);
                setPopupVisibility(false);
                setIsLoading(false)
                nextNav(0);
              })
              .catch((error) => {
                setMessage("Ha ocurrido un error al guardar la información.");
                setSeverity("error");
                setOpenMessage(true);
                setIsLoading(false)
              });
          } else {
            const banckInfo = {
              cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
              institucionContraparte: idBanco,
              isClave: clave.length > 0 ? true : false,
              isCheque: false,
            };
            if (validateCuentaBancaria(banckInfo) == false) {
              return;
            }
            apiClient
              .pabsBankInfo(banckInfo)
              .then((data) => {
                console.log(data);
                checkActiveCredit();
                setMessage("Informacion guardada correctamente..");
                setSeverity("success");
                setOpenMessage(true);
                setPopupVisibility(false);
                nextNav(0);
                setIsLoading(false)
              })
              .catch((error) => {
                setMessage("Ha ocurrido un error al guardar la información.");
                setSeverity("error");
                setOpenMessage(true);
                setIsLoading(false)
              });
          }
        } else if (contrato === "nomina") {
          if (paymentMethod === "check") {
            let backInfo = {
              isCheque: true,
            };
            apiClient
              .nominaBankInfo(backInfo)
              .then((data) => {
                console.log(data);
                setMessage("Informacion guardada correctamente..");
                setSeverity("success");
                setOpenMessage(true);
                setIsLoading(false)
              })
              .catch((error) => {
                setMessage("Ha ocurrido un error al guardar la información.");
                setSeverity("error");
                setOpenMessage(true);
                setIsLoading(false)
              });
          } else {
            const banckInfo = {
              cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
              institucionContraparte: idBanco,
              isClave: clave.length > 0 ? true : false,
              isCheque: false,
            };
            console.log(banckInfo);
            if (validateCuentaBancaria(banckInfo) == false) {
              return;
            }
            // if(avisoDePrivacidad == false){
            //   setOpenMessage(true);
            //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
            //   setSeverity("info");
            //   return;
            // }
            apiClient
              .nominaBankInfo(banckInfo)
              .then((data) => {
                setMessage("Informacion guardada correctamente..");
                setSeverity("success");
                setOpenMessage(true);
                setPopupVisibility(false);
                console.log(data);
                setIsLoading(false)
              })
              .catch((error) => {
                setMessage("Ha ocurrido un error al guardar la información.");
                setSeverity("error");
                setOpenMessage(true);
                setIsLoading(false)
              });
          }
        } else if (contrato === "personal") {
          console.log(paymentMethod);
          if (paymentMethod === "check") {
            let backInfo = {
              isCheque: true,
            };
            apiClient
              .personalBankInfo(backInfo)
              .then((data) => {
                ////nextNav(0);
                checkActiveCredit();
                setMessage("Informacion guardada correctamente..");
                setSeverity("success");
                setOpenMessage(true);
                console.log(data);
                setPopupVisibility(false);
                setIsLoading(false)
              })
              .catch((error) => {
                setMessage("Ha ocurrido un error al guardar la información.");
                setSeverity("error");
                setOpenMessage(true);
                setIsLoading(false)
              });
          } else {
            const banckInfo = {
              cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
              institucionContraparte: idBanco,
              isClave: clave.length > 0 ? true : false,
              //"isCheque" : false
            };
            if (validateCuentaBancaria(banckInfo) == false) {
              return;
            }
            // if(avisoDePrivacidad == false){
            //   setOpenMessage(true);
            //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
            //   setSeverity("info");
            //   return;
            // }
            apiClient
              .personalBankInfo(banckInfo)
              .then((data) => {
                //nextNav(0);
                checkActiveCredit();
                setMessage("Informacion guardada correctamente..");
                setSeverity("success");
                setOpenMessage(true);
                setPopupVisibility(false);
                console.log(data);
                setIsLoading(false)
              })
              .catch((error) => {
                setMessage("Ha ocurrido un error al guardar la información.");
                setSeverity("error");
                setOpenMessage(true);
                setIsLoading(false)
              });
          }
        } else if (contrato === "") {
          apiClient.getValidationTerms().then((resp) => {
            console.log(resp);
            const {
              acceptedTermAndPrivacy,
              userDetails,
              loanActive,
              acceptedContract,
            } = resp;
            console.log("Dashboard - getValidationTerms: ", resp);
            if (loanActive == true && acceptedContract == true) {
              apiClient.getActiveCredit().then((data) => {
                if (data.creditType === "nomina") {
                  if (paymentMethod === "check") {
                    let backInfo = {
                      isCheque: true,
                    };
                    apiClient
                      .nominaBankInfo(backInfo)
                      .then((data) => {
                        console.log(data);
                        setMessage("Informacion guardada correctamente..");
                        setSeverity("success");
                        setOpenMessage(true);
                      })
                      .catch((error) => {
                        setMessage(
                          "Ha ocurrido un error al guardar la información."
                        );
                        setSeverity("error");
                        setOpenMessage(true);
                      });
                  } else {
                    const banckInfo = {
                      cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
                      institucionContraparte: idBanco,
                      isClave: clave.length > 0 ? true : false,
                      isCheque: false,
                    };
                    console.log(banckInfo);
                    if (validateCuentaBancaria(banckInfo) == false) {
                      return;
                    }
                    // if(avisoDePrivacidad == false){
                    //   setOpenMessage(true);
                    //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
                    //   setSeverity("info");
                    //   return;
                    // }
                    apiClient
                      .nominaBankInfo(banckInfo)
                      .then((data) => {
                        setMessage("Informacion guardada correctamente..");
                        setSeverity("success");
                        setOpenMessage(true);
                        setPopupVisibility(false);
                        console.log(data);
                      })
                      .catch((error) => {
                        setMessage(
                          "Ha ocurrido un error al guardar la información."
                        );
                        setSeverity("error");
                        setOpenMessage(true);
                      });
                  }
                } else if (data.creditType === "personal") {
                  console.log(paymentMethod);
                  if (paymentMethod === "check") {
                    let backInfo = {
                      isCheque: true,
                    };
                    apiClient
                      .personalBankInfo(backInfo)
                      .then((data) => {
                        ////nextNav(0);
                        checkActiveCredit();
                        setMessage("Informacion guardada correctamente..");
                        setSeverity("success");
                        setOpenMessage(true);
                        console.log(data);
                        ////nextNav(0);
                        checkActiveCredit();
                        setPopupVisibility(false);
                      })
                      .catch((error) => {
                        setMessage(
                          "Ha ocurrido un error al guardar la información."
                        );
                        setSeverity("error");
                        setOpenMessage(true);
                      });
                  } else {
                    const banckInfo = {
                      cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
                      institucionContraparte: idBanco,
                      isClave: clave.length > 0 ? true : false,
                      //"isCheque" : false
                    };
                    if (validateCuentaBancaria(banckInfo) == false) {
                      return;
                    }
                    // if(avisoDePrivacidad == false){
                    //   setOpenMessage(true);
                    //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
                    //   setSeverity("info");
                    //   return;
                    // }
                    apiClient
                      .personalBankInfo(banckInfo)
                      .then((data) => {
                        ////nextNav(0);
                        checkActiveCredit();
                        setMessage("Informacion guardada correctamente..");
                        setSeverity("success");
                        setOpenMessage(true);
                        setPopupVisibility(false);
                        console.log(data);
                      })
                      .catch((error) => {
                        setMessage(
                          "Ha ocurrido un error al guardar la información."
                        );
                        setSeverity("error");
                        setOpenMessage(true);
                      });
                  }
                }
              });
            }
          });
        }
      });
    }
    console.log(
      "contrato",
      contrato,
      "metodo",
      paymentMethod,
      "institucion",
      idBanco,
      "clave",
      clave,
      "tarjeta",
      tarjeta
    );
    if (contrato === "pabs") {
      if (paymentMethod === "check") {
        let backInfo = {
          isCheque: true,
        };
        apiClient
          .pabsBankInfo(backInfo)
          .then((data) => {
            checkActiveCredit();
            setMessage("Informacion guardada correctamente..");
            setSeverity("success");
            setOpenMessage(true);
            console.log(data);
            setPopupVisibility(false);
            nextNav(0);
            setIsLoading(false)
          })
          .catch((error) => {
            setMessage("Ha ocurrido un error al guardar la información.");
            setSeverity("error");
            setOpenMessage(true);
            setIsLoading(false)
          });
      } else {
        const banckInfo = {
          cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
          institucionContraparte: idBanco,
          isClave: clave.length > 0 ? true : false,
          isCheque: false,
        };
        if (validateCuentaBancaria(banckInfo) == false) {
          return;
        }
        apiClient
          .pabsBankInfo(banckInfo)
          .then((data) => {
            console.log(data);
            checkActiveCredit();
            setMessage("Informacion guardada correctamente..");
            setSeverity("success");
            setOpenMessage(true);
            setPopupVisibility(false);
            setIsLoading(false)
            nextNav(0);
          })
          .catch((error) => {
            setMessage("Ha ocurrido un error al guardar la información.");
            setSeverity("error");
            setOpenMessage(true);
            setIsLoading(false)
          });
      }
    } else if (contrato === "nomina") {
      if (paymentMethod === "check") {
        let backInfo = {
          isCheque: true,
        };
        apiClient
          .nominaBankInfo(backInfo)
          .then((data) => {
            console.log(data);
            setMessage("Informacion guardada correctamente..");
            setSeverity("success");
            setOpenMessage(true);
            setIsLoading(false)
          })
          .catch((error) => {
            setMessage("Ha ocurrido un error al guardar la información.");
            setSeverity("error");
            setOpenMessage(true);
            setIsLoading(false)
          });
      } else {
        const banckInfo = {
          cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
          institucionContraparte: idBanco,
          isClave: clave.length > 0 ? true : false,
          isCheque: false,
        };
        console.log(banckInfo);
        if (validateCuentaBancaria(banckInfo) == false) {
          return;
        }
        // if(avisoDePrivacidad == false){
        //   setOpenMessage(true);
        //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
        //   setSeverity("info");
        //   return;
        // }
        apiClient
          .nominaBankInfo(banckInfo)
          .then((data) => {
            setMessage("Informacion guardada correctamente..");
            setSeverity("success");
            setOpenMessage(true);
            setPopupVisibility(false);
            console.log(data);
            setIsLoading(false)
          })
          .catch((error) => {
            setMessage("Ha ocurrido un error al guardar la información.");
            setSeverity("error");
            setOpenMessage(true);
            setIsLoading(false)
          });
      }
    } else if (contrato === "personal") {
      console.log(paymentMethod);
      if (paymentMethod === "check") {
        let backInfo = {
          isCheque: true,
        };
        apiClient
          .personalBankInfo(backInfo)
          .then((data) => {
            ////nextNav(0);
            checkActiveCredit();
            setMessage("Informacion guardada correctamente..");
            setSeverity("success");
            setOpenMessage(true);
            console.log(data);
            setPopupVisibility(false);
            setIsLoading(false)
          })
          .catch((error) => {
            setMessage("Ha ocurrido un error al guardar la información.");
            setSeverity("error");
            setOpenMessage(true);
            setIsLoading(false)
          });
      } else {
        const banckInfo = {
          cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
          institucionContraparte: idBanco,
          isClave: clave.length > 0 ? true : false,
          //"isCheque" : false
        };
        if (validateCuentaBancaria(banckInfo) == false) {
          return;
        }
        // if(avisoDePrivacidad == false){
        //   setOpenMessage(true);
        //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
        //   setSeverity("info");
        //   return;
        // }
        apiClient
          .personalBankInfo(banckInfo)
          .then((data) => {
            //nextNav(0);
            checkActiveCredit();
            setMessage("Informacion guardada correctamente..");
            setSeverity("success");
            setOpenMessage(true);
            setPopupVisibility(false);
            console.log(data);
            setIsLoading(false)
          })
          .catch((error) => {
            setMessage("Ha ocurrido un error al guardar la información.");
            setSeverity("error");
            setOpenMessage(true);
            setIsLoading(false)
          });
      }
    } else if (contrato === "") {
      apiClient.getValidationTerms().then((resp) => {
        console.log(resp);
        const {
          acceptedTermAndPrivacy,
          userDetails,
          loanActive,
          acceptedContract,
        } = resp;
        console.log("Dashboard - getValidationTerms: ", resp);
        if (loanActive == true && acceptedContract == true) {
          apiClient.getActiveCredit().then((data) => {
            if (data.creditType === "nomina") {
              if (paymentMethod === "check") {
                let backInfo = {
                  isCheque: true,
                };
                apiClient
                  .nominaBankInfo(backInfo)
                  .then((data) => {
                    console.log(data);
                    setMessage("Informacion guardada correctamente..");
                    setSeverity("success");
                    setOpenMessage(true);
                  })
                  .catch((error) => {
                    setMessage(
                      "Ha ocurrido un error al guardar la información."
                    );
                    setSeverity("error");
                    setOpenMessage(true);
                  });
              } else {
                const banckInfo = {
                  cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
                  institucionContraparte: idBanco,
                  isClave: clave.length > 0 ? true : false,
                  isCheque: false,
                };
                console.log(banckInfo);
                if (validateCuentaBancaria(banckInfo) == false) {
                  return;
                }
                // if(avisoDePrivacidad == false){
                //   setOpenMessage(true);
                //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
                //   setSeverity("info");
                //   return;
                // }
                apiClient
                  .nominaBankInfo(banckInfo)
                  .then((data) => {
                    setMessage("Informacion guardada correctamente..");
                    setSeverity("success");
                    setOpenMessage(true);
                    setPopupVisibility(false);
                    console.log(data);
                  })
                  .catch((error) => {
                    setMessage(
                      "Ha ocurrido un error al guardar la información."
                    );
                    setSeverity("error");
                    setOpenMessage(true);
                  });
              }
            } else if (data.creditType === "personal") {
              console.log(paymentMethod);
              if (paymentMethod === "check") {
                let backInfo = {
                  isCheque: true,
                };
                apiClient
                  .personalBankInfo(backInfo)
                  .then((data) => {
                    ////nextNav(0);
                    checkActiveCredit();
                    setMessage("Informacion guardada correctamente..");
                    setSeverity("success");
                    setOpenMessage(true);
                    console.log(data);
                    ////nextNav(0);
                    checkActiveCredit();
                    setPopupVisibility(false);
                  })
                  .catch((error) => {
                    setMessage(
                      "Ha ocurrido un error al guardar la información."
                    );
                    setSeverity("error");
                    setOpenMessage(true);
                  });
              } else {
                const banckInfo = {
                  cuentaBeneficiario: clave.length > 0 ? clave : tarjeta,
                  institucionContraparte: idBanco,
                  isClave: clave.length > 0 ? true : false,
                  //"isCheque" : false
                };
                if (validateCuentaBancaria(banckInfo) == false) {
                  return;
                }
                // if(avisoDePrivacidad == false){
                //   setOpenMessage(true);
                //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
                //   setSeverity("info");
                //   return;
                // }
                apiClient
                  .personalBankInfo(banckInfo)
                  .then((data) => {
                    ////nextNav(0);
                    checkActiveCredit();
                    setMessage("Informacion guardada correctamente..");
                    setSeverity("success");
                    setOpenMessage(true);
                    setPopupVisibility(false);
                    console.log(data);
                  })
                  .catch((error) => {
                    setMessage(
                      "Ha ocurrido un error al guardar la información."
                    );
                    setSeverity("error");
                    setOpenMessage(true);
                  });
              }
            }
          });
        }
      });
    }
  };
  const validateCuentaBancaria = (banckInfo: any) => {
    const { cuentaBeneficiario, institucionContraparte, isClave } = banckInfo;
    if (
      cuentaBeneficiario == null ||
      cuentaBeneficiario == "" ||
      (isClave
        ? cuentaBeneficiario.length < 18 || cuentaBeneficiario.length > 20
        : cuentaBeneficiario.length < 16 || cuentaBeneficiario.length > 16) ||
      institucionContraparte == null ||
      institucionContraparte == ""
    ) {
      setMessage("Favor de completar la información.");
      setSeverity("error");
      setOpenMessage(true);
      return false;
    }
    return true;
  };

  const getEstatusLoan = async () => {
    apiClient.getValidationTerms().then(async(resp) => {
      console.log(resp);
      const {
        acceptedTermAndPrivacy,
        userDetails,
        loanActive,
        acceptedContract,
      } = resp;
      console.log("Dashboard - getValidationTerms: ", resp);
      if (loanActive == true && acceptedContract == true) {
       await apiClient.getActiveCredit().then((data) => {
          console.log("getActiveCredit", data);
          setcontrato(data.creditType);
        });
      } else {
        // const noActive = await apiClient.getDeliveredCredit().then((data) => {
        //   console.log(data)
        //   const {creditType, id, status} = data;
        //   if( creditType === 'personal' ) {
        //     return history.push('/solicitud-monto/personal')
        //   } else if( creditType === 'diremovil') {
        //     return history.push('/solicitud-monto-diremovil')
        //   } else if( creditType === 'pabs' ){
        //     return history.push('/solicitud-monto/titulo')
        //   }
        // })
      }
    });
  };

  useEffect(() => {
    getEstatusLoan();
  }, []);
  useEffect(() => {
    getEstatusLoan();
  }, [nextNav]);

  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    let data = {
      cuentaBeneficiario: credit.cuentaBeneficiario,
      institucionContraparte: credit.institucionContraparte,
      isClave: credit.isClave,
      isCheque: credit.isCheque,
    };
    setStp(data);
    return activeCredit;
  };

  useEffect(() => {
    const getComments = () => {
      apiClient
      .getComentariosClient()
      .then((data) => {
        console.log("comentarios", data);
        setComentarios(data);
      })
      .catch((error) => {
        setMessage("Ha ocurrido un error al cargar los comentarios.");
        setSeverity("error");
        setOpenMessage(true);
      });
    };

    getComments();
  },[])
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="col-md-8 col-lg-10 d-md-block grey-background p-0 m-0"
    >
      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={'auto'}
        height={'auto'}
        showTitle={false}
      >
        <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
        />
        <ScrollView width="90%" height="90%">
          <div className="avisoContent">
            Probenso Group, Sociedad Anónima, Sociedad Financiera de Objeto
            Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur
            137, Colonia Centro, Tepic, 63000, Nayarit, México es el responsable
            de sus datos personales.Utilizaremos tus datos personales para los
            siguientes fines indispensables: (i) Crear tu cuenta de usuario en
            la plataforma alojada en https://percapita.mx/; (ii) Identificarte
            como usuario de la plataforma alojada en https://percapita.mx/;
            (iii) Generar el expediente de solicitud de tu crédito; (iv)
            Consultar tu historial y capacidad crediticia; (v) Decidir si se
            aprueba o rechaza tu solicitud de crédito, y; (vi) Firmar los
            contratos de crédito que sean aprobados.Para conocer el aviso de
            privacidad integral, por favor haz{" "}
            <a
              href="/aviso-de-privacidad"
              target="_blank"
              style={{ fontSize: 14, marginLeft: 5 }}
            >
              click aquí.{width}
            </a>
            <div className="row">
              <div className="d-flex justify-content-around mt-3">
                <button
                  type="submit"
                  className="btn btn-shadow"
                  onClick={handleBanckInfo}
                  disabled={loading}
                >
                  Aceptar
                </button>
                <button
                  className="btn btn-shadow-cancela"
                  onClick={() => setPopupVisibility(false)}
                  disabled={loading}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </ScrollView>
        <Backdrop
          sx={{ color: "#173A5E", zIndex: (theme) => theme.zIndex.appBar + 1 }}
          open={isLoading}
        >
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid  img-client"
            width="300"
          />
          <CircularProgress color="primary" size={80} />
        </Backdrop>
      </Popup>

      <div className="container-fluid mt-4">
        <h1 className="text-strong-blue">
          ¡Hola {userMain.nombre.split(" ")[0]}!
        </h1>
        <h4>Bienvenid@ a tu cuenta Percapita</h4>
        {comentarios.length > 0 ? (<>
          <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} >
          <Grid item xs={12} md={12} xl={12}>
            <Item>
              <Chip label="Mensajes" />
              <Divider light />
              {comentarios.map((comment) => (<>
                <List sx={style} component="nav" aria-label="mailbox folders">
                <Typography variant="h6" component="div">
                  {comment.comment}
                </Typography>
                <Divider light />
              </List>
              </>))}
              
            </Item>
          </Grid>
        </Grid>
      </Box>
      </>) : ''}
        
        {status === "IN_PROGRESS" ? (
          <div className="mt-5 mb-5 text-center">
            <h5 className="txt-blue-lig">
              ¡Estamos analizando tu solicitud de crédito!
            </h5>
            <h3 className="text-strong-blue">
              ¡No te preocupes! Estamos trabajando arduamente para determinar la
              viabilidad de tu solicitud por
              <br />
              {amount && (
                <b>
                  {" "}
                  $ {amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")} MXN.
                </b>
              )}
            </h3>
            <img
              src={logo2}
              alt="PRADO'S"
              className="img-fluid mt-5"
              width="200"
            />
          </div>
        ) : (
          contrato === "diremovil" || contrato === 'diremovil-promotion' || mCreditType === 'diremovil' || mCreditType === 'diremovil-promotion' ? (
            <div className="mt-5 mb-5 text-center">
            <h1 className="txt-blue-lig">Aquí podrás encontrar</h1>
            <h1 className="text-strong-blue">
              todos los detalles de tu crédito
            </h1>
            <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="alert alert-success col-sm-6" role="alert">
                    A la brevedad nos comunicaremos contigo para coordinar la entrega de tu equipo celular.
                  </div>
                  <div className="col-sm-3"></div>
                </div>
            <img
              src={logo2}
              alt="PRADO'S"
              className="img-fluid mt-5"
              width="200"
            />
            <div className="d-flex justify-content-center mt-5 mb-4">
              <a href="#" className="btn btn-shadow-2" onClick={redirect}>
                MI CRÉDITO ACTUAL
              </a>
            </div>
          </div>
          ) :
          (
          <div className="mt-5 mb-5 text-center">
            <h1 className="txt-blue-lig">Aquí podrás encontrar</h1>
            <h1 className="text-strong-blue">
              todos los detalles de tu crédito
            </h1>
            {stp.isClave === null &&
            stp.cuentaBeneficiario === null &&
            stp.institucionContraparte === null &&
            stp.isCheque === null ? (
              <div
                style={{
                  boxShadow: "0 -7px 17px rgba(45,115,172,.1)",
                  width: "100%",
                  marginTop: "5vw",
                }}
              >
                <h4 className="text-strong-blue">
                  ¡Felicidades por tu aprobación de crédito!
                </h4>
                <h5>¿A dónde quieres que te depositemos tu crédito?</h5>
                {/* <FormControl>
                  <RadioGroup
                    row
                    aria-label="payment"
                    name="payment"
                    value={paymentMethod}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="check"
                      control={<Radio color="primary" />}
                      label="Recibir Cheque Electrónico"
                    />
                    <FormControlLabel
                      value="transfer"
                      control={<Radio color="primary" />}
                      label="Transferencia electronica"
                    />
                  </RadioGroup>
                </FormControl> */}
                {paymentMethod === "transfer" && (
                  <>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div className="mb-2">
                        <label className="form-label sol1-tittle">
                          Institución bancaria
                        </label>
                        <select
                          className="form-select slect-blue text-light"
                          onChange={(e) => setIdBanco(e.target.value)}
                        >
                          <option value="0">Seleccione un banco</option>
                          {instituciones
                            .sort((a, b) => {
                              if (a.label == b.label) {
                                return 0;
                              }
                              if (a.label < b.label) {
                                return -1;
                              }
                              return 1;
                            })
                            .map((banco: any) => (
                              <option value={banco.code}>{banco.label}</option>
                            ))}
                        </select>
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="antiquityMonth"
                          className="form-label sol1-tittle"
                        >
                          Cuenta bancaria
                        </label>
                        <input
                          className="form-control"
                          minLength={18}
                          maxLength={20}
                          placeholder="CLABE interbancaria"
                          disabled={tarjeta.length > 0 ? true : false}
                          type="text"
                          id="clabe"
                          name="clabe"
                          onChange={(e) => setClave(e.target.value)}
                        />
                      </div>
                      <div className="mb-2">
                        <label className="form-label sol1-tittle">
                          Número de tarjeta
                        </label>
                        <input
                          className="form-control"
                          minLength={16}
                          maxLength={16}
                          placeholder="12348598798"
                          type="text"
                          disabled={clave.length > 0 ? true : false}
                          id="tarjeta"
                          name="tarjeta"
                          onChange={(e) => setTarjeta(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    
                  </div>
                <button
                  className="btn btn-shadow-2"
                  onClick={() => setPopupVisibility(true)}
                >
                  Continuar
                </button>
                  </>
                )}
                <br></br>
                <FormControl>
                  <RadioGroup
                    row
                    aria-label="payment"
                    name="payment"
                    value={paymentMethod}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="check"
                      control={<Radio color="primary" />}
                      label="No tengo cuenta de debito a mi nombre"
                    />
                  </RadioGroup>
                </FormControl>
                {paymentMethod === "check" && (
                  <button
                  className="btn btn-shadow-2"
                  onClick={() => setPopupVisibility(true)}
                >
                  Pedir orden de cobro
                </button>
                )}
              </div>
            ) : stp.isCheque === true &&
              stp.isClave === null &&
              stp.cuentaBeneficiario === null &&
              stp.institucionContraparte === null ? (
              <div
                style={{
                  boxShadow: "0 -7px 17px rgba(45,115,172,.1)",
                  width: "100%",
                  marginTop: "5vw",
                }}
              >
                <h4 className="text-strong-blue">
                  ¡Felicidades por tu aprobación de crédito!
                </h4>
                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="alert alert-success col-sm-6" role="alert">
                    A la brevedad te enviaremos tu cheque electrónico al número
                    telefónico registrado.
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            ) : (stp.isCheque === null || stp.isCheque === false) &&
              stp.isClave !== null &&
              stp.cuentaBeneficiario !== null &&
              stp.institucionContraparte !== null ? (
              <div
                style={{
                  boxShadow: "0 -7px 17px rgba(45,115,172,.1)",
                  width: "100%",
                  marginTop: "5vw",
                }}
              >
                <h4 className="text-strong-blue">
                  ¡Felicidades por tu aprobación de crédito!
                </h4>
                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="alert alert-success col-sm-6" role="alert">
                    A la brevedad depositaremos tu dinero en la cuenta
                    registrada.
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            ) : (
              ""
            )}

            <img
              src={logo2}
              alt="PRADO'S"
              className="img-fluid mt-5"
              width="200"
            />
            <div className="d-flex justify-content-center mt-5 mb-4">
              <a href="#" className="btn btn-shadow-2" onClick={redirect}>
                MI CRÉDITO ACTUAL
              </a>
            </div>
          </div>
        )
        )}
      </div>
    </motion.div>
  );
};
const instituciones = [
  { code: "2001", label: "BANXICO" },
  { code: "37006", label: "BANCOMEXT" },
  { code: "37009", label: "BANOBRAS" },
  { code: "37019", label: "BANJERCITO" },
  { code: "37135", label: "NAFIN" },
  { code: "37166", label: "BaBien" },
  { code: "37168", label: "HIPOTECARIA FED" },
  { code: "40002", label: "BANAMEX" },
  { code: "40012", label: "BBVA MEXICO" },
  { code: "40014", label: "SANTANDER" },
  { code: "40021", label: "HSBC" },
  { code: "40030", label: "BAJIO" },
  { code: "40036", label: "INBURSA" },
  { code: "40042", label: "MIFEL" },
  { code: "40044", label: "SCOTIABANK" },
  { code: "40058", label: "BANREGIO" },
  { code: "40059", label: "INVEX" },
  { code: "40060", label: "BANSI" },
  { code: "40062", label: "AFIRME" },
  { code: "40072", label: "BANORTE" },
  { code: "40106", label: "BANK OF AMERICA" },
  { code: "40108", label: "MUFG" },
  { code: "40110", label: "JP MORGAN" },
  { code: "40112", label: "BMONEX" },
  { code: "40113", label: "VE POR MAS" },
  { code: "40127", label: "AZTECA" },
  { code: "40128", label: "AUTOFIN" },
  { code: "40129", label: "BARCLAYS" },
  { code: "40130", label: "COMPARTAMOS" },
  { code: "40132", label: "MULTIVA BANCO" },
  { code: "40133", label: "ACTINVER" },
  { code: "40136", label: "INTERCAM BANCO" },
  { code: "40137", label: "BANCOPPEL" },
  { code: "40138", label: "ABC CAPITAL" },
  { code: "40140", label: "CONSUBANCO" },
  { code: "40141", label: "VOLKSWAGEN" },
  { code: "40143", label: "CIBANCO" },
  { code: "40145", label: "BBASE" },
  { code: "40147", label: "BANKAOOL" },
  { code: "40148", label: "PAGATODO" },
  { code: "40150", label: "INMOBILIARIO" },
  { code: "40151", label: "DONDE" },
  { code: "40152", label: "BANCREA" },
  { code: "40154", label: "BANCO COVALTO" },
  { code: "40155", label: "ICBC" },
  { code: "40156", label: "SABADELL" },
  { code: "40157", label: "SHINHAN" },
  { code: "40158", label: "MIZUHO BANK" },
  { code: "40159", label: "BANK OF CHINA" },
  { code: "40160", label: "BANCO S3" },
  { code: "90600", label: "MONEXCB" },
  { code: "90601", label: "GBM" },
  { code: "90602", label: "MASARI" },
  { code: "90605", label: "VALUE" },
  { code: "90608", label: "VECTOR" },
  { code: "90616", label: "FINAMEX" },
  { code: "90617", label: "VALMEX" },
  { code: "90620", label: "PROFUTURO" },
  { code: "90630", label: "CB INTERCAM" },
  { code: "90631", label: "CI BOLSA" },
  { code: "90634", label: "FINCOMUN" },
  { code: "90638", label: "NU MEXICO" },
  { code: "90642", label: "REFORMA" },
  { code: "90646", label: "STP" },
  { code: "90652", label: "CREDICAPITAL" },
  { code: "90653", label: "KUSPIT" },
  { code: "90656", label: "UNAGRA" },
  { code: "90659", label: "ASP INTEGRA OPC" },
  { code: "90661", label: "ALTERNATIVOS" },
  { code: "90670", label: "LIBERTAD" },
  { code: "90677", label: "CAJA POP MEXICA" },
  { code: "90680", label: "CRISTOBAL COLON" },
  { code: "90683", label: "CAJA TELEFONIST" },
  { code: "90684", label: "TRANSFER" },
  { code: "90685", label: "FONDO (FIRA)" },
  { code: "90686", label: "INVERCAP" },
  { code: "90689", label: "FOMPED" },
  { code: "90699", label: "FONDEADORA" },
  { code: "90703", label: "TESORED" },
  { code: "90710", label: "NVIO" },
  { code: "90722", label: "Mercado Pago W" },
  { code: "90723", label: "CUENCA" },
  { code: "90902", label: "INDEVAL" },
  { code: "90903", label: "CoDi Valida" },
  { code: "91812", label: "BBVA BANCOMER" },
  { code: "90706", label: "ARCUS" },
  { code: "90728", label: "SPIN BY OXXO" },
  { code: "90699", label: "FONDEADORA" },
  {code: '90728', label: 'SPIN BY OXXO'},
];

const instituciones2 = instituciones.sort((a, b) => {
  if (a.label == b.label) {
    return 0;
  }
  if (a.label < b.label) {
    return -1;
  }
  return 1;
});
const mapStateToProps = ({ navReducer }) => ({
  user: navReducer?.user ?? {},
  nominaId: navReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextNav,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Inicio);
