import "./App.css";
import Home from "./components/front/HomeI";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import DomicioProvider from "./context/DomicilioProvider";
import TramiteCreditoProvider from "./context/TramiteCreditoProvider";
import PreTramiteCreditoProvider from "./context/PreTramiteCreditoProvider";
import UserProvider from "./context/UserProvider";
import UserCambaceoProvider from "./context/UserCambaceoProvider";
import "./css/style.css";
import PrivateRoute from "./components/PrivateRoute";
import RutasPrivadas from "./utils/RutasPrivadas";
import rutasPublicas from "./utils/RutasPublicas";
import "./css/light/css/dx.light.css";
import { useState } from "react";
import { NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE } from "./utils/config";
import {FloatUser} from './components/float.component'
import UserDiremovilProvider from "./context/UserDiremovilProvider";
import { FloatUserDiremovil } from "./components/float.component-diremovil"
import CacheBuster from 'react-cache-buster';
//import { buildVersion } from '../package.json';
//import Loading from './loading';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "./img/logo_blanco.png";

function App() {
  const privateRoutes = RutasPrivadas;
  return (
    <div className="App">

      <CacheBuster
        currentVersion={"0.5.11"}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
          open={true}
        >
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-3 img-client"
            width="300"
          />
          <CircularProgress color="primary" size={80} />
        </Backdrop>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >

        <UserDiremovilProvider>
          <UserCambaceoProvider>
            <PreTramiteCreditoProvider>
              <UserProvider>
                <DomicioProvider>
                  <TramiteCreditoProvider>
                    <FloatUser />
                    <FloatUserDiremovil />
                    <Router>
                      <Switch>
                        <Route exact path="/">
                          <Home />
                        </Route>
                        {privateRoutes.map(({ path, component }) => (
                          <PrivateRoute path={path} component={component} />
                        ))}
                        {rutasPublicas.map(({ path, component }) => (
                          <Route path={path} component={component} />
                        ))}
                        <Route path="*" component={() => <Redirect to="/" />} />
                      </Switch>
                    </Router>
                  </TramiteCreditoProvider>
                </DomicioProvider>
              </UserProvider>
            </PreTramiteCreditoProvider>
          </UserCambaceoProvider>
        </UserDiremovilProvider>
      </CacheBuster>
    </div>
  );
}

export default App;
